import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import FormSearchTeacher from '@uz/unitz-tool-components/FormSearchTeacher';
import B2BAddStudentModal from '@uz/unitz-tool-components/B2BAddStudentModal';
import { Button } from '@uz/unitz-components-web/Button';
import { ctx } from '@vl/redata';
import gstyles from '@vl/gstyles';
import { Row, Col } from 'antd';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import CustomTable from '@uz/unitz-components-web/CustomTable';

const Index = () => {
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="m-4">
          <div className="sdasd">
            <FormSearchTeacher />
          </div>
          <div className="py-6 flex justify-between items-center">
            <div className="text-main font-semibold text-base">
              {ctx.apply('i18n.t', 'ZoomToolStudent.Table.title')}
            </div>
            <Row>
              <Col span={12}>
                <ModalProvider>
                  <Button
                    type="primary"
                    size="medium"
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return <B2BAddStudentModal />;
                      });
                    }}
                  >
                    <div className="flex items-center">
                      <div className="text-white500 font-semibold">
                        {ctx.apply('i18n.t', 'ZoomToolStudent.Action.add_student')}
                      </div>
                      <div>
                        {gstyles.icons({
                          name: 'plus',
                          size: 14,
                          fill: gstyles.colors.white500,
                          className: 'mx-2',
                        })}
                      </div>
                    </div>
                  </Button>
                </ModalProvider>
              </Col>
            </Row>
          </div>
          {renderPropsComposer(
            {
              matcher: (props) =>
                !_.get(props, 'items.length') &&
                !_.get(props, 'isLoading') &&
                !ctx.apply('paginationModel.hasFilterArgs'),
              render: () => (
                <Row justify="center" className="py-16">
                  <Col span={12}>
                    <div className="flex flex-col items-center justify-center">
                      <div className="mb-4">
                        {gstyles.icons({
                          name: 'no-result',
                          size: 152,
                        })}
                      </div>
                      <div className="mb-6 text-center text-2xl font-semibold text-sub">
                        {ctx.apply('i18n.t', 'ZoomToolStudent.empty_text')}
                      </div>
                    </div>
                  </Col>
                </Row>
              ),
            },
            {
              matcher: (props) => !_.get(props, 'items.length') && !!_.get(props, 'isLoading'),
              render: () => (
                <CustomTable
                  columns={ctx.get('tableColumn.columns')}
                  dataSource={ctx.get('tableColumn.dataSource', [])}
                  rowKey={(rec) => _.get(rec, 'id')}
                  loading={ctx.get('paginationModel.isLoading')}
                  pagination={ctx.get('tableColumn.pagination')}
                  onChange={ctx.get('paginationModel.onChange')}
                  size="middle"
                />
              ),
            },
            {
              matcher: (props) => !!_.get(props, 'items.length') || ctx.apply('paginationModel.hasFilterArgs'),
              render: () => (
                <CustomTable
                  columns={ctx.get('tableColumn.columns')}
                  dataSource={ctx.get('tableColumn.dataSource', [])}
                  rowKey={(rec) => _.get(rec, 'id')}
                  loading={ctx.get('paginationModel.isLoading')}
                  pagination={ctx.get('tableColumn.pagination')}
                  onChange={ctx.get('paginationModel.onChange')}
                  size="middle"
                />
              ),
            },
            () => null
          )(ctx.get('paginationModel'))}
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
