import React from 'react';

import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import _ from 'lodash';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import { notification } from 'antd';
import MemberModel from '@uz/unitz-models/B2BMemberModel';
import { PHONE_NUMBER } from '@vl/mod-utils/validateUtil';

const bindData = bindings({
  B2BEditMemberModal: {
    rules: [
      [
        'data',
        {
          data: {
            recordData: hook((ctx) => {
              return ctx.get('@item');
            }),
            form: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const account_id = ctx.apply('accountModel.getAccountId');
              const validationSchema = React.useMemo(() => {
                return Yup.object({});
              }, []);
              const avatarUrl = !ctx.get('recordData.member_profile.avatar_url')
                ? ''
                : [
                    {
                      url: ctx.get('recordData.member_profile.avatar_url'),
                      status: 'success',
                    },
                  ];

              const form = useFormik({
                enableReinitialize: false,
                initialValues: {
                  account_id,
                  user_id: ctx.get('recordData.user_id'),
                  first_name: ctx.get('recordData.member_profile.first_name'),
                  last_name: ctx.get('recordData.member_profile.last_name'),
                  avatar_url: avatarUrl,
                  date_of_birth: ctx.get('recordData.member_profile.date_of_birth'),
                  place_of_birth: ctx.get('recordData.member_profile.place_of_birth'),
                  email: ctx.get('recordData.member_profile.email'),
                  phone: ctx.get('recordData.member_profile.phone'),
                  note: ctx.get('recordData.member_profile.note'),
                  roles: _.map(ctx.get('recordData.member_roles'), (role) => {
                    return {
                      id: _.get(role, 'role.role_id'),
                    };
                  }),
                },
                validateOnChange: true,
                validateOnBlur: false,
                onSubmit: async (values, actions) => {
                  try {
                    const res = await MemberModel.updateMemberProfile({
                      user_id: values.user_id,
                      id: ctx.get('recordData.member_profile.id'),
                      account_id,
                      set_profile: {
                        first_name: values.first_name,
                        last_name: values.last_name,
                        date_of_birth: values.date_of_birth,
                        place_of_birth: values.place_of_birth,
                        email: values.email,
                        phone: values.phone,
                        avatar_url: !!_.isArray(_.get(values, 'avatar_url'))
                          ? _.get(values, 'avatar_url.0.url', '')
                          : values.avatar_url,
                        note: values.note,
                      },
                      roles: !!_.isEmpty(values.roles)
                        ? []
                        : _.map(values.roles, (role) => {
                            return {
                              account_id,
                              user_id: values.user_id,
                              role_id: _.get(role, 'id'),
                            };
                          }),
                    });
                    if (!!res) {
                      ctx.apply('paginationModel.onRefresh');
                      notification.success({
                        message: ctx.apply('i18n.t', 'ZoomToolMember.Edit_Form.Notification.edit_success'),
                      });
                      ctx.apply('modalModel.hide');
                    }
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({
                  first_name: Yup.string().required(ctx.apply('i18n.t', 'JobPost.Validation.required')),
                  last_name: Yup.string().required(ctx.apply('i18n.t', 'JobPost.Validation.required')),
                  email: Yup.string()
                    .email()
                    .required(ctx.apply('i18n.t', 'JobPost.Validation.required')),
                  phone: Yup.string().matches(PHONE_NUMBER, ctx.apply('i18n.t', 'JobPost.Validation.phone')),
                }),
              });

              _.assign(form, {
                isModalVisible,
                canSubmit: !form.isSubmitting && form.dirty && form.isValid,
                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
                handleChangeCheckbox: (e) => {
                  const { checked, name } = e.target;
                  if (checked) {
                    form.setFieldValue('roles', [...form.values.roles, { id: name }]);
                  } else {
                    form.setFieldValue(
                      'roles',
                      _.filter(_.get(form, 'values.roles'), (v) => {
                        return _.get(v, 'id') !== name;
                      })
                    );
                  }
                },
                getPermission: (() => {
                  const roles_query = ctx.get('roleOptions');
                  const roles_values = _.get(form, 'values.roles');
                  const filter = React.useMemo(
                    () =>
                      _.filter(roles_query, (item) => {
                        return _.some(roles_values, (item_values) => {
                          return _.get(item, 'role_id', '') === _.get(item_values, 'id', '');
                        });
                      }),
                    [roles_values]
                  );

                  const data = [];
                  const list = _.map(filter, (role_permissions) => {
                    return _.map(_.get(role_permissions, 'role_permissions', []), (role_permission) =>
                      data.push(role_permission)
                    );
                  });
                  return _.uniqBy(data, 'permission.name');
                })(),
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
